:root {
  --white: #f0f3f7;
  --light2: #f5f8fc;
  --light: #dde4ed;
  --brand: #147cc2;
  --brand-LS: #6598ba;
  --brand-IC: #0c639d;
  --pop: #199ef7;
  --gray: #727779;
  --lightpop: #bce4ff;
  --dark: #0d4f7b;
  --blacktrans: rgba(0;0;0;.6);
  --brandtrans: rgba(20;124;194; .65);
  --branddarktrans: rgba(8;60;97; .85);
  --top-banner-h: 27px;
  --header-h: 5rem;
  --header-md-h: 123px;
  --header-lg-h: calc(8.25rem + var(--top-banner-h));
  --header-xl-h: calc(5rem + var(--top-banner-h));
  --padding-md-t-offset: 2rem;
  --padding-lg-t-offset: 2rem;
  --padding-xl-t-offset: 4rem;
  --content-xl-t-padding: 3rem;
  --absolute-b-offset: calc(var(--header-h)  + 8.5rem);
  --absolute-t-offset: calc(var(--header-h)  + var(--padding-lg-t-offset));
  --hack-safe-inset: 8rem;
  --safe-content-h: calc(100vh - var(--absolute-t-offset)  - var(--absolute-b-offset)  + var(--hack-safe-inset));
  --sticky-md-t-offset: calc(var(--header-md-h)  + var(--padding-md-t-offset));
  --sticky-lg-t-offset: var(--header-lg-h);
  --sticky-xl-t-offset: calc(var(--header-xl-h)  + var(--content-xl-t-padding)  + var(--padding-xl-t-offset)  - var(--top-banner-h));
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --2xl: 1536px;
}

* {
  box-sizing: border-box;
}

html {
  background-color: var(--white);
  scroll-behavior: instant;
  font-size: 100%;
}

blockquote, blockquote p:last-child {
  margin: 0;
}

pre {
  max-width: 100%;
  overflow-x: scroll;
}

body {
  touch-action: manipulation;
  font-size: 1rem;
}

a {
  font-weight: 400;
}

a, a:hover {
  transition: none;
}

a:hover {
  text-decoration: none;
}

img {
  display: inline-block;
}

th {
  font-weight: 700;
}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid;
}

:after, :before {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: normal;
  font-variation-settings: normal;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  color: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dd, dl, fieldset, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  color: #9ca3af;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: #9ca3af;
  opacity: 1;
}

input::placeholder, textarea::placeholder {
  color: #9ca3af;
  opacity: 1;
}

[role="button"], button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :after, :before, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.bottom-\[0px\] {
  bottom: 0;
}

.bottom-\[var\(--absolute-b-offset\)\] {
  bottom: var(--absolute-b-offset);
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-\[var\(--absolute-t-offset\)\] {
  top: var(--absolute-t-offset);
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-\[-2rem\] {
  margin-left: -2rem;
  margin-right: -2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-\[calc\(80px\+56px\)\] {
  margin-bottom: 136px;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[calc\(100\%-2rem\)\] {
  width: calc(100% - 2rem);
}

.w-full {
  width: 100%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.\!translate-x-\[0\] {
  --tw-translate-x: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.translate-x-\[calc\(100\%-1rem\)\] {
  --tw-translate-x: calc(100% - 1rem);
}

.scale-x-\[0\.97\], .translate-x-\[calc\(100\%-1rem\)\] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[0\.97\] {
  --tw-scale-x: .97;
}

.scroll-mt-\[120px\] {
  scroll-margin-top: 120px;
}

.scroll-mt-\[140px\] {
  scroll-margin-top: 140px;
}

.scroll-mt-\[calc\(80px\+120px\)\] {
  scroll-margin-top: 200px;
}

.scroll-mt-\[calc\(var\(--header-height\)\+50px\)\] {
  scroll-margin-top: calc(var(--header-height)  + 50px);
}

.list-inside {
  list-style-position: inside;
}

.list-decimal {
  list-style-type: decimal;
}

.list-none {
  list-style-type: none;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.\!overflow-x-hidden {
  overflow-x: hidden !important;
}

.\!overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
}

.rounded-br-lg, .rounded-r-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t-2 {
  border-top-width: 2px;
}

.\!border-brand {
  border-color: rgb(148 52 163 / var(--tw-border-opacity)) !important;
}

.\!border-brand, .\!border-pop {
  --tw-border-opacity: 1 !important;
}

.\!border-pop {
  border-color: rgb(185 65 204 / var(--tw-border-opacity)) !important;
}

.border-brand {
  --tw-border-opacity: 1;
  border-color: rgb(148 52 163 / var(--tw-border-opacity));
}

.border-light {
  --tw-border-opacity: 1;
  border-color: rgb(221 228 237 / var(--tw-border-opacity));
}

.border-light\/10 {
  border-color: #dde4ed1a;
}

.border-light\/100 {
  border-color: #dde4ed;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(240 243 247 / var(--tw-border-opacity));
}

.border-b-brand {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(148 52 163 / var(--tw-border-opacity));
}

.border-l-brand {
  --tw-border-opacity: 1;
  border-left-color: rgb(148 52 163 / var(--tw-border-opacity));
}

.\!bg-light {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(221 228 237 / var(--tw-bg-opacity)) !important;
}

.bg-darker {
  background-color: rgb(228 235 245 / var(--tw-bg-opacity));
}

.bg-darker, .bg-light {
  --tw-bg-opacity: 1;
}

.bg-light {
  background-color: rgb(221 228 237 / var(--tw-bg-opacity));
}

.bg-light2 {
  background-color: rgb(245 248 252 / var(--tw-bg-opacity));
}

.bg-light2, .bg-white {
  --tw-bg-opacity: 1;
}

.bg-white {
  background-color: rgb(240 243 247 / var(--tw-bg-opacity));
}

.bg-white\/100 {
  background-color: #f0f3f7;
}

.bg-white\/80 {
  background-color: #f0f3f7cc;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-sm {
  font-size: .875rem;
}

.text-xl {
  font-size: 1.25rem;
}

.\!font-bold {
  font-weight: 700 !important;
}

.font-bold {
  font-weight: 700;
}

.leading-\[4\.5rem\] {
  line-height: 4.5rem;
}

.\!text-brand {
  --tw-text-opacity: 1 !important;
  color: rgb(148 52 163 / var(--tw-text-opacity)) !important;
}

.text-black\/100 {
  color: #000;
}

.text-black\/70 {
  color: #000000b3;
}

.text-black\/80 {
  color: #000c;
}

.text-brand {
  color: rgb(148 52 163 / var(--tw-text-opacity));
}

.text-brand, .text-pop {
  --tw-text-opacity: 1;
}

.text-pop {
  color: rgb(185 65 204 / var(--tw-text-opacity));
}

.\!no-underline {
  text-decoration-line: none !important;
}

.opacity-100 {
  opacity: 1;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow, .shadow-inner {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

@-moz-document url-prefix() {
  .moz-bdb-fallback, .stat-bar-container {
    background-color: #020f19a6;
  }
}

@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

:is(.dark .dark\:bg-dark) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 14 23 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-dark-lighter) {
  --tw-bg-opacity: 1;
  background-color: rgb(7 47 74 / var(--tw-bg-opacity));
}

:is(.dark .dark\:\!text-brand-LS) {
  --tw-text-opacity: 1 !important;
  color: rgb(101 152 186 / var(--tw-text-opacity)) !important;
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(240 243 247 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  .md\:sticky {
    position: sticky;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:top-\[var\(--sticky-md-t-offset\)\] {
    top: var(--sticky-md-t-offset);
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:max-h-\[calc\(100vh-var\(--sticky-md-t-offset\)\)\] {
    max-height: calc(100vh - var(--sticky-md-t-offset));
  }

  .md\:max-h-fit {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  .md\:min-w-\[30\%\] {
    min-width: 30%;
  }

  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scroll-mt-\[170px\] {
    scroll-margin-top: 170px;
  }

  .md\:scroll-mt-\[180px\] {
    scroll-margin-top: 180px;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(240 243 247 / var(--tw-bg-opacity));
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 1024px) {
  .lg\:top-\[var\(--sticky-lg-t-offset\)\] {
    top: var(--sticky-lg-t-offset);
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:max-h-\[calc\(100vh-var\(--sticky-lg-t-offset\)\)\] {
    max-height: calc(100vh - var(--sticky-lg-t-offset));
  }

  .lg\:w-4\/5 {
    width: 80%;
  }
}

@media (min-width: 1280px) {
  .xl\:sticky {
    position: sticky;
  }

  .xl\:top-\[80px\] {
    top: 80px;
  }

  .xl\:top-\[calc\(121px\+80px\)\] {
    top: 201px;
  }

  .xl\:top-\[var\(--sticky-xl-t-offset\)\] {
    top: var(--sticky-xl-t-offset);
  }

  .xl\:-mx-16, .xl\:mx-\[-4rem\] {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:max-h-\[calc\(100vh-var\(--sticky-xl-t-offset\)\)\] {
    max-height: calc(100vh - var(--sticky-xl-t-offset));
  }

  .xl\:scroll-mt-\[calc\(201px\+2rem\)\] {
    scroll-margin-top: calc(201px + 2rem);
  }

  .xl\:scroll-mt-\[calc\(201px\+4rem\)\] {
    scroll-margin-top: calc(201px + 4rem);
  }

  .xl\:rounded-xl {
    border-radius: .75rem;
  }

  .xl\:border-2 {
    border-width: 2px;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:pt-\[var\(--content-xl-t-padding\)\] {
    padding-top: var(--content-xl-t-padding);
  }

  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

/*# sourceMappingURL=handbook.a6494887.css.map */
